.header-wrapper {
  background-color: $blackBackground;
  width: 100%;
  position: sticky;
  z-index: 10;
  top: 0;
}

.header-page {
  @include mobileContainer;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;

  .logo{
    &-block {
      width: 34.28571%;
      overflow: hidden;
      width: 40px;
      height: 46px;
      img {
        min-width: 194px;
        height: 46px;
      }
    }
    &__link {
      display: block;
    }

    @include w600 {
      &-block {
        width: 100%;
      }
    }
  }

  .modal-open {
    display: block;
    width: 70.85714%;
    height: 40px;
    
    @include w600 {
      height: 50px;
    }
  }
}

.buttons__wrapper {
  width: 63%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}