.page-spare__category-list {
  
  &__item {
    width: 100%;
  }
}

.page-spare__features {

  &-container {
    @include mobileContainer;
  }

  &-list {
    flex-direction: column;

    &__item {
      width: 100%;
      flex-direction: column;
      align-items: center;

      i {
        margin: 15px 0 15px 15px;
      }
    }
  }
}

.page-spare__category-list__item {
  .page-spare__category-list__item-overlay {
    .button {
      padding: 20px 25px;
    }
  }
}