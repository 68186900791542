.menu-wrapper {
  background-color: $blackBackground;
  position: fixed;
  top: 0;
  right: -335px;
  height: 100vh;
  width: 84%;
  max-width: 335px;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 14px;
  transition: $transition;
  opacity: 0;

  .contacts-block { 
    @include w600 {
      max-height: 200px;
    }
  }
}

.menu__title {
  font-family: 'Muller';
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
  background-color: $globalColorLink;
  padding: 25px 19px;
  margin: 0;
}

.menu__list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-weight: 300;
}

.menu__item {
  &.hasChild {
    position: relative;

    .submenu__list {
      display: none
    }

    &:before {
      content: '';
      position: absolute;
      left: 6%;
      top: 47%;
      border: 2.5px solid transparent; 
      border-top: 2.5px solid $globalColorLink;
    }

    &.active {
      .submenu__list {
        display: block;
      }
      &:before {
        border: none;
      }
    }
  }
}

.menu__link {
  padding: 16.1px 27px;
  color: white;
  letter-spacing: .4px;
  border-bottom: 1px solid #3d4048;
  display: block;
  width: 100%;
}

.region {
  padding: 16.1px 27px;
  color: #fff;

  a {
    color: #fff;
    border-bottom: 1px dotted #fff;
    margin: 0 0 0 5px;
  }
}

.contacts-block {
  padding: 16.1px 27px 16.1px 59px;
  height: 30%;
  // max-height: 300px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Muller';
  
  a {
    color: #fff;
  }
}

.contacts__phone {
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: .08em;
}

.contacts__address {
  font-style: normal;
  color: #f1f1f1;
  line-height: 18px;
  font-weight: 300;
}

.submenu__list {
  list-style: none;
  padding: 16.1px 27px;
  
}

.submenu__item {
  .submenu__link {
    color: #7d868c;
    display: block;
    padding: 5px 0;
  }

  &.center {
    text-align: center;
  }

  &.submenu__title {
    i {
      font-size: 18px;
    }
    .submenu__link {
      font-size: 14px;
      text-transform: uppercase;
      color: #fff;
    }
  }

}