.spares__wrapper {
  padding: 50px 16px;
  @include mobileContainer;

  .button {
    margin-top: 30px;
  }
}

.spares__card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__item {
    width: 48%;
    min-height: 97px;
    margin-bottom: 15px;
    padding: 15px;

    &__category-name {
      width: 100%;
      color: $blackBackground;
      font-size: 12px;
      line-height: 18px;
      text-transform: uppercase;
      font-family: 'Muller';
      letter-spacing: .35px;
      font-weight: 600;
    }

  }

  .spares__card-list__item__icon-category img {
    opacity: .5;
  }

  @include w600 {
    justify-content: center;

    &__item {
      width: 32%;
      margin-left: 1.3%;
      padding: 24px 36px 22px;

      &__category-name {
        font-size: 14px;
        line-height: 23px;
        font-weight: 300;
      }
    }
  }
}