.page-wrapper {
  .page__services-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;

    &.reverseOrder {
      .page__services__image {
        order: 1;
      }

      .page__services__attendance {
        order: 2;
      }
    }
    
    &.page-container {
      padding: 40px 16px;
    }
  }
}

.page__services__image {
  width: 100%;
  margin-right: 15px;
  margin: 80px auto 20px;

  &.green-circle {
    &::before {
      margin: auto;
      top:0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 350px;
      height: 350px;
    }
  }
}

.page__services__attendance {
  width: 100%;

  .page-title {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.page__services__list {


  .spares__card-list__item {
    width: 100%;
  }

  .spares__card-list__item__category-name {
    margin: 0;
    padding: 5px 0;
  }
}

.black-theme {
  .page__services__image {
    display: none;
  }

  .page__services__attendance {
    width: 100%;
  
    .page-title {
      text-align: center;
      position: static;
      margin: 0 0 25px;
    }
  }
}

// additionally

.page__additionally {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;

  .page-title {
    margin: 0;
    font-size: 24px;
  }

  .button {
    padding: 20px 30px;
    margin: 20px 0;
  }
}

@include w768 {
  .page-wrapper {
    .page__services-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;

    }
  }
  .page__services__image  {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;

    &::before {
      margin: auto;
      top:0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  .page__services__list {
    flex-direction: row;
    justify-content: space-between;

    .spares__card-list__item {
      width: 30%;
    }


  }

  .black-theme {
    .spares__card-list__item__icon-category {
      opacity: .5;
    }
  }
}

.title-container {
  .features  {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;

    &-item {
      width: 30%;
    }
  }
}

.price-list {
  table {
    td {
      width: 40%;
    }
  }
}