.contacts-page {
  flex-wrap: wrap;
  flex-direction: column;
}

.contacts-page__contacts-wrapper {
  width: 100%;
}

.contacts-form {
  width: 100%;

  .form__container {
    .form {
      max-width: 570px;
      margin: auto;

      input[type="submit"] {
        margin: 15px auto;
      }
    }
  }
}