.company-branch__wrapper {
  background-color: #f6f7f8;
  padding: 65px 0;

  .company-branch__container {
    @include mobileContainer;
  }
  .button {
    max-width: 141px;

    @include w600 {
      margin-top: 70px;
    }
  }
}

.company-branch__slider {
  display: none;
}