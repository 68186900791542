// this file contain style for promotionCard.html

.promotion-card {
  width: 100%;
  margin-bottom: 15px;
}

@include w600 {
  .promotion-card {
    width: 48%;
  }
}