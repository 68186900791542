.services__wrapper {
  width: 100%;
  padding: 40px 16px 10px;
  min-height: 400px;
  position: relative;

  .services__container {
    @include mobileContainer;
  }

  &:before {
    max-height: 400px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: -1;
  }
  &:after {
    z-index: -3;
  }

  &:after, &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.services-truck__wrapper {
  background-color: rgba(255,255,255, .8);
  &:before {
    border-radius: 50%;
    right: 0;
    background-image: url(../img/services/gruz.jpg);
  }

  .services__container {
    .services__block__title {
      color: $blackBackground;
    }

    .services__block__list {
      .services__list-item {
        &:before{
          background-color: black;
        }
      }
    }

    .services__list-link {
      color: $blackBackground;
    }
  }
}

.services-trailer__wrapper {
  background-color: rgba(43,46,55, .8);
  color: #fff;

  &:before {
    border-radius: 50%;
    right: 0;
    background-image: url(../img/services/grunwaVehicle1.jpg);
  }

  &:after {
    width: 100%;
    height: 100%;
    background-color: $blackBackground;
  }

  .services__block {

    &:not(:last-child):after {
      content: '';
      position: absolute;
      top: 16px;
      bottom: -35px;
      left: 1px;
      width: 1px;
      background-color: #3c4048;
    }
  }
}

.services__container {
  .services__block {
    position: relative;

    &.active {
      .services__block__title {
        font-weight: 700;
      }

      .arrow-icon {
        &::before {
          transform: rotate(-135deg);
        }

        &::after {
          transform: rotate(135deg);
        }
      }

      .services__block__list .services__list-item {
        display: block;
      }
    }
  }

  .services__block__title {
    position: relative;
    padding-left: 33px;
    text-transform: uppercase;
    font-family: 'Muller';
    font-size: 18px;
    color: #fff;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: .8px;

    &:before {
      content: '';
      position: absolute;
      top: 11px;
      left: -1px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: $globalColorLink;
    }


  }

  .services__block__list {
    margin-left: 20px;
    list-style-type: disc;
      list-style-position: inside;
    
    .services__list-item {
      display: none;
      padding: 4px 0;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        top: 50%;
        left: -20px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #ccc;
      }
    }
  }

  .services__list-link {
    color: #ccc;
    font-size: 14px;
    line-height: 1.8;
    letter-spacing: .25px;
    text-transform: lowercase;

  }
}