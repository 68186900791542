.car-covers__features {
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 30px 0;

  &-item {
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
  }
}

@include w768 {
  .car-covers__features {
    &-item {
      width: 30%;
    }
  }

  .page__car-covers-services {
    .spares__card-list__item {
      width: 48%;
    }
  }
}