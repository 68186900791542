.form__wraper {
  padding: 40px 0;

  .form__container {
    width: 85vw;
    padding: 40px 15px;
    
    .form {
      margin-top: 15px;
    }

    .subtitle {
      font-family: 'Fira Sans Extra Condensed', sans-serif;
      font-size: 20px;
      line-height: 22px;
      letter-spacing: .5px;
      font-weight: 300;
    }

    input[type="submit"] {
      margin: 0 auto 15px;
      width: auto;
    }

    @include w600 {
      padding: 40px 5%;

      .form__checkbox {
        width: 100%;
        max-width: 600px;
        margin: 0;
      }
    }
  }

  @include w600 {
    input {
      max-width: 600px;
    }

    .subtitle {
      text-align: left;
      margin: 0 auto;
      max-width: 600px;
      padding-left: 15px;
    }

    .form {
      margin: 20px 0 0;
    }
  }

  @include w768 {
    .form {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .input {
        width: 27%;
      }

      input[type="submit"] {
        margin: 0;
        order: 5;
      }

      .form__checkbox {
        order: 1;
        margin-bottom: 10px;
      }

      .button {
        margin: 0 0 25px;
      }
    }
  }
}