.page-wrapper {
  .page-container {
    @include mobileContainer;

    p {
      img {
        object-fit: contain;
        max-height: 300px;
      }
    }
  }
}

.form__container input[type="text"] {
  color: #2b2e37;
}

.page-description {

  &.block {
    @include mobileContainer;
    margin: 50px auto;
  }
}

.service-title-block {
  @include mobileContainer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 50px;

  &__description {
    width: 100%;
  }

  &__image {
    display: none;
  }
}

.section__text {
  img {
    object-fit: contain;
    margin: 0 auto;

    @media (max-width: 600px) {
      max-height: 250px;

    }
  }
}

article {
  @include mobileContainer;
}