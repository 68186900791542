.breadcrumbs__container {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;

  .breadcrumbs__link {
    display: block;
    padding-bottom: 2px;
    margin-bottom: 5px;
  }
}