.form-popup__wraper {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  height: auto;
  width: 100vw;
  max-width: $mobileMaxWidth;
  overflow: auto;
  display: none;

  &.active {
    display: block;
    z-index: 11;
  }

  .form__container {
    .form {
      input {
        margin: 0 auto;

        ~label {
          margin: 0 0 20px;
        }
      }
    }
  }
}

.form__container {
  @include w768 {
    width: 50vw;
  }

  .subtitle {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    color: #fff;
    font-size: 16px;
    letter-spacing: .25px;
    line-height: 22px;
    margin: 0 auto;
    text-align: center;
    font-weight: 300;
  }

  .form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;


    .input-label {
      color: white;
      max-width: 400px;
    }

    button {
      display: block;
      margin: 30px auto 0;
    }
  }

  input {
    width: 100%;
    max-width: 400px;
    padding: 16.5px 18px;
    font-size: 14px;
    font-weight: 300;
    font-family: inherit;
    border: none;
    border-radius: 2px;
    // margin: 0 0 20px;
    margin: 0 auto;

    
  }

  .input {
    margin: 0 0 20px;
  }
  
  .form__inputs-block.left {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
  }

  textarea {
    display: none;
  }

  .form__checkbox {
    max-width: 400px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: flex-start;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: .1px;
    font-family: 'Muller';
    color: #7d868c;
    font-weight: 300;

    label {
      padding-left: 30px;
    }

    label:before {
      content: '';
      position: absolute;
      top: -5px;
      left: 0;
      width: 20px;
      height: 20px;
      border-radius: 2px;
      border: 1px solid #dfe1e2;
      background-color: #fff;
      text-align: center;
      line-height: 18px;
    }

    [type=checkbox]:checked+label:before {
      content: "\f00c";
      font-family: 'Font Awesome 5 Free';
      font-size: 10px;
      color: #7d868c;
    }

    input {
      position: absolute;
      z-index: 2;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}

.region-popup__link .arrowLink {
  font-size: 5px;
}

.popup-wrapper {
  right: 0;
  margin: auto;
}