.promotion__wrapper {
  margin: 50px auto;
  
  .promotion__container {
    @include mobileContainer;
    padding: 50px 16px;
    background-position: top;
  }
}

.promotion-list {
  margin: 30px 0 10px;

  &__item {
    align-items: center;

    &-img {
      width: 40%;
    }

    &-description {
      width: 55%;
    }
  }
}

@include w600 {
  .promotion__wrapper {
    
    .promotion__container {
      background-position: right;
    }
  }

  .promotion__wrapper {
    width: 95%;
    margin: 50px auto;
  }

  .promotion-list {
    padding: 0 50px;

    &__item {

      &-img {
        width: 20%;
      }

      &-description {
        width: 80%;
      }
    }
  }

  .promotion__title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
  }
}