@import './variables.scss';
@import './mixins';
@import './fonts';
@import './mobile/general';
@import './mobile/header';
@import './mobile/menu';
@import './mobile/formPopup';
@import './mobile/button';
@import './mobile/footer.scss';
@import './mobile/pages/promotionsList';
@import './mobile/pages/mainPage';
@import './mobile/pages/contacts';
@import './mobile/pages/serviceMaintenance';
@import './mobile/pages/carCovers';
@import './mobile/pages/restorationPage';
@import './mobile/pages/sparePage';
@import './mobile/pages/technicsPage';
@import './mobile/breadcrumbs';
@import './mobile/pages/gydrofications';

body {
  position: relative;
  min-width: 320px;
  margin: 0;
  overflow-x: hidden;
  letter-spacing: .25px;
}

.main-wrapper {

  transition: $transition;
}

.text-container {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: .45px;
  font-weight: 300;

  a {
    color: $globalColorLink;
    text-decoration: underline;
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 12px;
      font-size: 16px;
      line-height: 22px;

      &:before {
        content: '';
        position: absolute;
        top: 49%;
        left: 0;
        width: 3px;
        height: 3px;
        background-color: $globalColorLink;
      }
    }
  }

  h2 {
    @include w600 {
      font-size: 32px;
      line-height: 40px;
    }
  }
}

.page-title {
  word-break: break-word;
  font-size: 24px;
}

.page-text-space {
  font-size: 14px;
  line-height: 22px;

  ul {
    font-size: 16px;
    letter-spacing: .3px;
    line-height: 22px;
  }
}

.reconstruction {
  .service-title-block {
    display: flex;
    flex-wrap: wrap;
  }

  .service-title-block__image {
    display: block;
    order: -1;
  }

  .service-title-block__description {
    text-align: left;

    .button {
      max-width: 130px;
      height: 50px;
    }
  }
}