.model-raw__container {
  @include mobileContainer;
}

.swiper-container {
  margin: 40px 0;
  .swiper-slider {
    img {
      height: 100px;
      width: 482px;
    }
  }

  .swiper-button {
    background-color: rgba(0,0,0,.6);
    border-radius: 50%;
    &-prev, &-next {
      color: #fff;

      &::after {
        content: '';
      }
    }

    &-prev {
      left: 0%;
    }

    &-next {
      right: 0%;
    }
  }
}
