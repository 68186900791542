.gydrofication__tech-types {
  padding: 40px 0;

    .page-title.margin {
      margin-top: 0;
    }


  &__list {

    &-item {
      width: 100%;

      @include w600 {
        width: calc(50% - 15px);
      }

      @include w768 {
        width: calc(33% - 5px);
      }
    }
  }
}

.gydrofication__suppliers {
  // padding: 15px 0;

  .page-title.margin {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  &__item {
    &__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &-img {
        width: 100%;
        margin: auto;
        text-align: center;
        margin-bottom: 30px;
      }

      &-text {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: .7px;
        font-family: 'Muller';
      }
    }

    &__slider__item {
      max-height: 250px;
    }
  }
}