.restoration-services__list-item {
  width: 95%;
  margin-bottom: 40px;
}

@include w768 {
  .restoration-services__list {
    justify-content: space-around;
  }

  .restoration-services__list-item {
    width: 45%;
  }
}