.footer-wrapper {
 
  .footer-container {
    @include mobileContainer;
  }

  .contacts-block {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    padding: 16.1px 27px 16.1px 45px;
  }

  .contacts__icon {
    margin-bottom: 30px;
    padding-left: 32px;

    &::before {
      left: 0;
    }
  }

  .contacts__address {
    font-size: 15px;
  }

  .contacts__mail {
    font-size: 13px;
  }
}

.footer-menu {
  padding: 20px 16px;

  &.footer-line {
    &:after {
      left: 5%;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__link {
    color: #fff;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: .2px;

    &-title {
      margin: 10px 0;
      color: $globalColorLink;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: .45px;
      font-family: 'Muller';
    }
  }
}

.footer-line {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
    opacity: .4;
  }
}

.footer-copyright {
  padding: 0 35px 0 16px;

}

@media (min-width: 625px) {
  .footer-logo {
    width: 100%;
  }

  .footer__contacts-wrapper {
    display: flex;
    justify-content: space-between;
    margin-left: 5.42857%;

    .contacts__address {
      font-size: 15px;
      line-height: 18px;
      font-weight: 300;
      font-family: "Fira Sans Extra Condensed",sans-serif;

      &::before {
        top: 0;
      }
    }
  }

  .footer-wrapper {
    .contacts__icon {
      max-width: 170px;
      margin: 10px 0 0;
      padding-left : 32px;
      &::before {
        left: 0;
      }
    }
    
    .footer-container {
      .contacts-block {
        width: 100%;
        max-height: none;
        height: auto;
        padding: 16.1px 27px 16.1px 20px;
      }
    }

    .footer-menu {
      width: 100%;
      padding: 20px 16px 10px;

      &.footer-line {
        &::after {
          left: 2.5%;
          width: 95%;
        }
      }

      &__withChilds {
        width: 100%;
        display: flex;
        justify-content: center;

        .footer-menu__list {
          width: 39%;
        }
      }

      &__withoutChilds {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .footer-menu__list {
          margin-top: 15px;
          width: 30%;
          text-align: center;

          &:last-child {
            width: 20%;
          }
        }
      }
    }

    .footer-copyright {
      padding: 20px 20px 30px;
    }
  }
}