.production__wrapper {
  padding: 50px 0 0;
  @include mobileContainer;

  @include w600 {
    padding: 0 25px;
  }
}

.production__description {
  padding: 0 40px 0 16px;
  font-weight: 300;
}

.production__service-block {
  display: flex;
  flex-direction: column;
  padding: 30px 10px;
  margin-bottom: 30px;
  color: white;
  position: relative;
  overflow: hidden;

  p {
    color: #fff;
  }

  @include w600 {
    &.reverseOrder {
      .production__service-block__description {
        order: 2;
      }

      .production__service-block__list {
        order: 1;
      }
    }

    .mobile-background {
      display: none;
    }
  }

  // styles for background image
  &:before {
    content: '';
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    background-repeat: no-repeat;
    background-size: cover;

  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $blackBackground;
    opacity: 0.9;
    z-index: -1;

    @include w600 {
      background-color: transparent;
    }
  }

  .mobile-background {
    position: absolute;
    top: 0;
    // right: 150px;
    right: 0;
    width: 180%;
    height: 100%;
    z-index: -2;
    text-align: right;
    img {
      height: 100%;
      object-fit: contain; 
      background-color: rgba($color: #000000, $alpha: .8);
    }
  }

  &.tents {
    .mobile-background {
      img {
        transform: rotateY(180deg);
      }
    }
    // &:before {
    //   // content: url(../img/production-services/service-right-md.jpg);
    // }

    @include w600 {
      &:before {
        background: none;
      }

      .production__service-block__description {
        &:before {
          background-image: url(../img/production-services/service-right-md.jpg)
        }
      }

      .production__service-block__list {
        &:before {
          background-position: right;
          background-image: url(../img/production-services/service-left-sm.jpg)
        }
      }
    }
  }

  &.trailer {
    @include w600 {
      &:before {
        background: none;
      }

      .production__service-block__description {
        min-height: 370px;

        &:before {
          background-position: right;
          background-image: url(../img/production-services/service-left-md.jpg)
        }
      }

      .production__service-block__list {
        min-height: 370px;

        &:before {

          background-image: url(../img/production-services/service-right-sm.jpg)
        }
      }
    }
  }

  &__description {
    .button {
      display: none;
    }

    @include w600 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 69%;
      padding: 0 10.7142857143%;
      min-height: 300px;
      border: 1px solid #dee0e2;
      position: relative;

      &.text-right {
        text-align: right;
        align-items: flex-end;

        .button {
          padding: 0 29px;
        }
      }

      .button {
        display: flex;
      }

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -2;
        top: 0;
        right: 0;
      }

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        background-color: $blackBackground;
        opacity: 0.9;
      }
    }
  }

  &__title {
    text-transform: uppercase;
    font-weight: 300;
    font-family: 'Muller';
    font-size: 22px;
    line-height: 30px;
    color: $globalColorLink;
    letter-spacing: 1.1px;
    margin: 0;
  }

  &__text {
    font-size: 16px;
    line-height: 22px;
  }

  &__list {
    list-style: circle;
    padding: 0 20px;
    margin: 0 0 38px;
    position: relative;

    &-item {
      font-size: 13px;

      a {
        color: #fff;
      }
    }

    @include w600 {
      width: 30%;
      min-height: 300px;
      border: 1px solid #dee0e2;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 10px;

      &-item {
        font-size: 12px;
        line-height: 18px;
        font-family: 'Muller';
        text-transform: uppercase;
        padding: 10px 0;

        a {
          padding: 10px 0;
          color: $blackBackground;
        }
      }

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -2;
        top: 0;
        right: 0;
      }
    }
  }

  @include w600 {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    .mobile-button {
      display: none;
    }
  }
}